import { useState } from 'react';
import { CardContent, Grid, useMediaQuery, Button, Typography, Theme } from '@mui/material';
import { Icon24InfoBgcFilled } from '@care/react-icons';
import { Card } from '@care/react-component-lib';
import { AmplitudeEvent, trackAmplitudeEvent, SeoFeatureInteracted } from '@/lib/analyticsHelper';
import { getCaregiver_getCaregiver_member as member } from '@/__generated__/getCaregiver';
import { SafetyModalWrapper } from '../SafetyModalWrapper';

export type CareCheckBoxProps = {
  firstName: member['firstName'];
  caregiverStateCode: string | null;
};

const CareCheckBox = ({ firstName, caregiverStateCode }: CareCheckBoxProps) => {
  const isMobileOrSmaller = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const badgeText = isMobileOrSmaller
    ? 'Completed a CareCheck'
    : `${firstName} completed a CareCheck`;

  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleBackgroundCheck = () => {
    setOpenModal(true);

    const amplitudeData: SeoFeatureInteracted = {
      feature_name: 'background check banner',
    };

    trackAmplitudeEvent(AmplitudeEvent.seoFeatureInteracted, amplitudeData);
  };

  return (
    <>
      <Card variant="outlined" sx={(theme: Theme) => ({ borderRadius: theme.spacing(2) })}>
        <CardContent
          sx={{
            px: 3,
            ':last-child': {
              pb: 2,
            },
          }}>
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Icon24InfoBgcFilled />
            </Grid>
            <Grid item xs>
              <Typography variant="h4">{badgeText}</Typography>
              <Typography variant="body3">
                This is the background check we require for all caregivers.{' '}
                <Button
                  variant="text"
                  sx={(theme) => ({
                    textDecoration: 'underline',
                    display: 'inline',
                    height: 'inherit',
                    fontSize: theme.typography.body3.fontSize,
                    textTransform: 'none',
                    padding: 0,
                  })}
                  component="button"
                  onClick={handleBackgroundCheck}>
                  Learn more
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <SafetyModalWrapper
        openModal={openModal}
        setOpenModal={setOpenModal}
        isCaregiverProfilePage
        caregiverStateCode={caregiverStateCode}
      />
    </>
  );
};

export { CareCheckBox };
