import { HTLBIDSlot } from '@care/htl-bid';
import { Box, Grid } from '@mui/material';
import { HTL_SLOTS } from '@/constants';
import { useResponsive } from './hooks/useResponsive';

interface GAMAdsProps {
  slotName: string;
  slotProps?: { 'data-testid'?: string };
}

const GAMAds = ({ slotName, slotProps }: GAMAdsProps) => {
  const { isSmallViewport, isMediumViewport } = useResponsive();
  const dimensions = (() => {
    if (isSmallViewport) {
      return { width: 320, height: 50 };
    }
    if (isMediumViewport) {
      return { width: 468, height: 50 };
    }
    if (slotName === HTL_SLOTS.SEO_DIRECTORY_RR_1) {
      return { width: 300, height: 250 };
    }
    if (slotName === HTL_SLOTS.SEO_PROV_PROFILE_RR_1) {
      return { width: 300, height: 600 };
    }

    return { width: 728, height: 90 };
  })();

  const { width, height } = dimensions;

  return (
    <Grid
      className={slotName}
      item
      xs={12}
      display="flex"
      justifyContent="center"
      pt={{ xs: 1, sm: 5 }}
      pb={{ xs: 4, sm: 8 }}
      sx={{
        '&.seo-prov-profile-inline-1, &.seo-prov-profile-inline-2': {
          pt: { xs: 4, md: 16 },
          pb: 4,
        },
        '&.seo-prov-profile-inline-2': {
          pt: { xs: 4 },
          pb: 4,
        },
      }}>
      <Box width={`${width}px`} height={`${height}px`} {...slotProps} overflow="visible">
        <HTLBIDSlot slotName={slotName} />
      </Box>
    </Grid>
  );
};

export { GAMAds };
