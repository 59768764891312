import { Box, Grid } from '@mui/material';
import { Divider } from '@care/react-component-lib';
import { getSimilarCaregivers_seoSimilarCaregiverProfiles_SEOSimilarCaregiverProfilesSuccess_similarCaregiverProfiles_caregiverList as similarCaregiverProfile } from '@/__generated__/getSimilarCaregivers';
import { SimilarCaregivers } from '../SimilarCaregivers';

const SimilarCaregiverSection = ({ profiles }: { profiles: similarCaregiverProfile[] }) => {
  return (
    <Grid item>
      <Box component="section" mt={{ xs: 2, lg: 4 }} width="100%">
        <Divider />
        <SimilarCaregivers profiles={profiles} />
      </Box>
    </Grid>
  );
};

export { SimilarCaregiverSection };
