import React from 'react';
import { HiredBy } from '@care/caregiver-profile-components';
import { ProfileAvatar } from '@care/react-component-lib';
import { Box, Container, Grid, useMediaQuery, Theme } from '@mui/material';
import {
  getCaregiver_getCaregiver as CaregiverType,
  getCaregiver_getCaregiver_hiredByCounts as hiredByCountsType,
} from '@/__generated__/getCaregiver';
import { RevieweeMetrics_revieweeMetrics_RevieweeMetricsPayload as RevieweeMetrics } from '@/__generated__/RevieweeMetrics';
import { CaregiverBadge, ReviewInfoRatingType } from '@/__generated__/globalTypes';
import { ActiveProfile } from '@/types/common';
import { ProfileData } from './ProfileData';
import { JoinToContact } from './JoinToContact';

type CaregiverPayRange = NonNullable<ActiveProfile['payRange']>;

export interface CaregiverCardProps {
  address: CaregiverType['member']['address'];
  badges: CaregiverType['badges'];
  baseHourlyRate?: CaregiverPayRange['hourlyRateFrom']['amount'];
  displayName: CaregiverType['member']['displayName'];
  enrollmentURL: string;
  hiredByCount?: hiredByCountsType['locality'];
  neighborhood: CaregiverType['placeInfo'];
  profileImage: CaregiverType['member']['hiResImageURL'];
  revieweeMetrics: RevieweeMetrics['metrics'] | null;
  experience: number | undefined;
  /**
   * Props to pass to slots contained within the component.
   */
  slotProps?: {
    /** Props to pass to the ProfileAvatar component contained within CaregiverCard */
    profileAvatar?: Omit<React.ComponentProps<typeof ProfileAvatar>, 'children'> & {
      [key: `data-${string}`]: boolean | string | number;
    };
  };
}

const CaregiverCard = ({
  address,
  badges,
  baseHourlyRate,
  displayName,
  enrollmentURL,
  hiredByCount,
  neighborhood,
  profileImage,
  revieweeMetrics,
  experience,
  slotProps,
}: CaregiverCardProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const avgOverallRating =
    revieweeMetrics &&
    revieweeMetrics.averageRatings.find((rating) => rating.type === ReviewInfoRatingType.OVERALL);

  const mapBadgeList = (badgeList: CaregiverType['badges']) => {
    if (badgeList === null) return [];
    return badgeList.map((badge) => {
      switch (badge) {
        case CaregiverBadge.BACKGROUND_CHECK:
          return 'BACKGROUND_CHECK';
        case CaregiverBadge.PREMIUM:
          return 'PREMIUM';
        default:
          return undefined;
      }
    });
  };

  const formatCaregiverNeighborhood = ({
    caregiverNeighborhood,
    caregiverAddress,
  }: {
    caregiverNeighborhood: CaregiverCardProps['neighborhood'];
    caregiverAddress: CaregiverCardProps['address'];
  }) => {
    if (caregiverNeighborhood && caregiverAddress) {
      return `${caregiverNeighborhood.name}, ${caregiverAddress.city}, ${caregiverAddress.state}  ${caregiverAddress.zip}`;
    }
    if (caregiverAddress) {
      return `${caregiverAddress.city}, ${caregiverAddress.state} ${caregiverAddress.zip}`;
    }
    return undefined;
  };

  return (
    <>
      <Box
        bgcolor={(theme: Theme) => theme.palette.background.latte}
        py={isMobile ? '12px' : 3}
        component="section"
        data-testid="caregiver-card">
        <Container maxWidth="md" disableGutters={isDesktop}>
          <Grid container>
            <Grid container item spacing={isMobile ? 3 : 4} md={8}>
              <Grid item>
                <ProfileAvatar
                  src={profileImage ?? undefined}
                  size={isMobile ? 'squareSmall' : 'squareXLarge'}
                  variant="square"
                  {...slotProps?.profileAvatar}
                />
              </Grid>
              <Grid container item xs justifyContent="space-between">
                <Grid item xs md={12}>
                  <ProfileData
                    badges={mapBadgeList(badges)}
                    showBackgroundCheckText={isDesktop}
                    displayName={displayName}
                    experience={experience || undefined}
                    neighborhood={formatCaregiverNeighborhood({
                      caregiverNeighborhood: neighborhood,
                      caregiverAddress: address,
                    })}
                    rate={
                      typeof baseHourlyRate === 'undefined'
                        ? baseHourlyRate
                        : parseInt(baseHourlyRate, 10)
                    }
                    rating={
                      avgOverallRating
                        ? {
                            count: revieweeMetrics.totalReviews,
                            value: avgOverallRating.value,
                          }
                        : undefined
                    }
                  />
                  {!isMobile && hiredByCount && hiredByCount.hiredCount > 0 && (
                    <Box mt={2} data-testid="desktop-hiredby">
                      <HiredBy count={hiredByCount.hiredCount} bold />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {isDesktop && (
              <Grid container item md={4} justifyContent="flex-end">
                <Box width={300}>
                  <JoinToContact ctaText="Join to contact" enrollmentURL={enrollmentURL} />
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
      {isMobile && hiredByCount && hiredByCount.hiredCount > 0 && (
        <Box my={2} data-testid="mobile-hiredby">
          <Container>
            <HiredBy count={hiredByCount.hiredCount} bold />
          </Container>
        </Box>
      )}
    </>
  );
};

export default CaregiverCard;
