import { LDEvaluationDetail } from 'launchdarkly-node-server-sdk';
import { UI_FEATURE_FLAGS } from '@/constants';
import { useFeatureFlags } from '@/components/contexts/FeatureFlagsContext';

type ClientFeatureFlags = keyof typeof UI_FEATURE_FLAGS;

export const useLDClientFlags = (): Partial<
  Record<ClientFeatureFlags, LDEvaluationDetail | undefined>
> => {
  const featureFlags = useFeatureFlags();

  const activeFlags: Partial<Record<ClientFeatureFlags, LDEvaluationDetail | undefined>> = {};

  return Object.entries(UI_FEATURE_FLAGS).reduce((acc, flag) => {
    const [flagName, flagDetails] = flag;
    const currentFlag = featureFlags?.flags?.[flagDetails.id];
    acc[flagName as ClientFeatureFlags] = currentFlag;
    return acc;
  }, activeFlags);
};
