import { Dispatch, SetStateAction } from 'react';
import { OverallRating } from '@care/react-component-lib';
import { Icon24UtilityChevronRight } from '@care/react-icons';
import { Box, Grid, Typography, Button } from '@mui/material';
import { RevieweeMetrics_revieweeMetrics_RevieweeMetricsPayload_metrics as RevieweeMetricsType } from '@/__generated__/RevieweeMetrics';
import { ReviewsByReviewee_reviewsByReviewee_ReviewsByRevieweePayload as ReviewsByRevieweeType } from '@/__generated__/ReviewsByReviewee';
import { ReviewInfoRatingType } from '@/__generated__/globalTypes';
import { AmplitudeEvent, SeoFeatureInteracted, trackAmplitudeEvent } from '@/lib/analyticsHelper';
import { ReviewCard } from './ReviewCard';

export interface MostRecentReviewProps {
  reviews: ReviewsByRevieweeType['reviews'];
  averageRatings: RevieweeMetricsType['averageRatings'];
  totalReviews: RevieweeMetricsType['totalReviews'];
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  openModal: boolean;
}

const MostRecentReview = ({
  reviews,
  averageRatings,
  totalReviews,
  setOpenModal,
  openModal,
}: MostRecentReviewProps) => {
  // Find the review with a rating equal to or above 3, otherwise return the latest review
  const getLatestReview = () => {
    const latestReview = reviews.find((review) => {
      return review.ratings.find((rating) => {
        return rating.type === ReviewInfoRatingType.OVERALL && rating.value >= 3;
      });
    });

    return latestReview ?? reviews[0];
  };

  const showAllReviews = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const amplitudeData: SeoFeatureInteracted = {
      feature_name: 'Reviews Modal',
    };

    trackAmplitudeEvent(AmplitudeEvent.seoFeatureInteracted, amplitudeData);
    setOpenModal(!openModal);
  };

  const latestReview = getLatestReview();
  const averageRating =
    averageRatings.find((rating) => rating.type === ReviewInfoRatingType.OVERALL) ??
    averageRatings[0];

  return (
    <Grid item xs={12}>
      {averageRating && (
        <Box mb={2} data-testid="unified-profile-overall-rating">
          <OverallRating
            overallRating={Number(averageRating.value.toFixed(1))}
            totalReviews={totalReviews}
          />
        </Box>
      )}
      {latestReview && (
        <Box mb={{ xs: 2, md: 4 }} data-testid="unified-profile-latest-review">
          <ReviewCard review={latestReview} />
        </Box>
      )}
      {reviews.length > 1 && (
        <Button
          sx={(theme) => ({
            color: theme.palette.care.black,
            backgroundColor: theme.palette.care.white,
            border: 'none',
            padding: 0,
            width: '100%',
            display: 'flex',
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer',
              color: theme.palette.secondaryButton.light,
            },
          })}
          onClick={showAllReviews}>
          <Typography variant="h4" component="span">
            Read all reviews
          </Typography>
          <Box pl={1} mt={1} component="span">
            <Icon24UtilityChevronRight />
          </Box>
        </Button>
      )}
    </Grid>
  );
};

export { MostRecentReview };
