import React from 'react';
import { Box, Grid, GridProps, Typography, Theme } from '@mui/material';
import { Divider } from '@care/react-component-lib';

interface SectionProps extends GridProps {
  title: string;
  displayDivider?: boolean;
  additionalComponent?: React.ReactNode;
  useH1Variant?: boolean;
  headerBottomMargin?: number;
  /**
   * Props to pass to slots contained within the component.
   */
  slotProps?: {
    /** Props to pass to the Divider component contained within Section */
    divider?: Omit<React.ComponentProps<typeof Divider>, 'children'> & {
      [key: `data-${string}`]: boolean | string | number;
    };
  };
}

const Section: React.FC<SectionProps> = ({
  children,
  displayDivider = false,
  title,
  additionalComponent,
  useH1Variant = false,
  headerBottomMargin = 3,
  slotProps,
}: SectionProps) => {
  return (
    <Grid container item xs={12}>
      <Box component="section" mt={0} width="100%">
        {displayDivider && <Divider {...slotProps?.divider} />}
        <Grid container item xs={12} justifyContent="space-between">
          <Box mb={{ xs: headerBottomMargin }}>
            <Typography
              variant={useH1Variant ? 'h1' : 'h2'}
              fontSize={(theme: Theme) => theme.typography.h2.fontSize}>
              {title}
            </Typography>
          </Box>
          {additionalComponent && <Box mb={{ xs: 3 }}>{additionalComponent}</Box>}
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Box>
    </Grid>
  );
};

export { Section };
